body{
    height: 100%;
    font-family: 'Montserrat';
    background-color: #202327;
}
#root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    display: relative;
}
.main {    
    display: flex;
    flex: 1 1 auto;
    color: #FFFFFF;
    font-family: 'Montserrat';
    justify-content: center;
}
.container {
    display: flex;
    flex-direction: column;
    max-width: 1320px;
    padding: 0px 10px;
    width: 100%;
    min-height: 100%;
}
.title{
    &-h1{
        font-size: 34px;
    }
}

.gold {
    color: #cfb28b !important;
    font-weight: 700;
}