.sendManagePanel{
    display: flex;
    flex-direction: column;
    width: 100%;
    &__title{

    }
    &__panel{
        display: flex;
        flex-direction: row;
        width: 100%;
        & >*:not(:last-child){
            margin-right: 20px;
        }
    }
    &__button{
        background-color: #cfb28b;
        height: 41px;
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        border: 0;
    }
}
.ListSendButton{
    &__button{
        display: flex;
        background-color: transparent;
        height: 35px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        border: 0;
        color: #cfb28b;
        font-size: 13px;
        align-items: center;
        &-text{
            margin: 0px 10px;
        }
    }
}