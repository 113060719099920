.PostFile{
    display: flex;
    flex-direction: column;
    & > *{
        margin: 10px 0px;
    }
    &__image{
        object-fit: cover;
        max-width: 624px;
    }
}