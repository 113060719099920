.Login{
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #202327;
    width: 100%;
    height: 100%;
    &Page{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__form{
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 400px;
            & > *{
                margin: 20px 0px;
            }
            &-input{
                border: 0;
                min-width: 400px;
                min-height: 50px;
                padding: 0px 10px;
                background-color: white;
                border-radius: 8px;
                color: black;
                &:hover{
                    border: 0;
                    outline: 0;
                }
                &:focus{
                    border: 0;
                    outline: 0;
                }
            }
            &-button{
                border: 0;
                min-width: 300px;
                min-height: 50px;
                background-color: #cfb28b;
                color: white;
                border-radius: 8px;
                font-size: 24px;
            }
        }
    }
}