.Place{
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
    width: 80%;
    border-bottom: white 1px solid;
    justify-content: space-between;
    &List{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__img{
        border-radius: 5px;
        width: 200px;
        object-fit: cover;
    }
}